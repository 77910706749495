import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthState from './context/auth/AuthState';
import Collection from './pages/Collection';
import ForgotPassword from './components/UserAuth/ForgotPassword';
import Login from './components/UserAuth/Login';
import Register from './components/UserAuth/Register';
import ResetPassword from './components/UserAuth/ResetPassword';
import setAuthToken from './utils/setAuthToken';
import Members from './pages/Members';
import MemberState from './context/members/MemberState';
import Contributions from './pages/Contributions';
import Navbar from './components/Navbar';
import Profile from './pages/Profile';
import MemberContribution from './pages/MemberContribution';
import ErrorPage from './components/ErrorPage';
import ContributionState from './context/contributions/ContributionState';
import Reports from './pages/Reports';

// Initiate token header...
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  return (
    <>
      <AuthState>
        <MemberState>
          <ContributionState>
            <Router>
              <div className='container'>
                <Routes>
                  <Route path='/' element={<Navbar />}>
                    <Route path='/collection' element={<Collection />} />
                    <Route path='/contributions' element={<Contributions />} />
                    <Route
                      path='/contributions/:id/:name/:no'
                      element={<MemberContribution />}
                    />
                    <Route path='/members' element={<Members />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/reports' element={<Reports />} />
                  </Route>
                  <Route path='/login' element={<Login />} />
                  <Route path='/register' element={<Register />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/reset-password' element={<ResetPassword />} />
                  <Route path='*' element={<ErrorPage />} />
                </Routes>
              </div>
            </Router>
          </ContributionState>
        </MemberState>
      </AuthState>
      <ToastContainer />
    </>
  );
}

export default App;
