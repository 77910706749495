export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS';
export const PASSWORD_FAIL = 'PASSWORD_FAIL';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAIL = 'EMAIL_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

// set loading
export const SET_LOADING = 'SET_LOADING';

//Members Mkoba
export const ADD_MEMBER = 'ADD_MEMBER';
export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBERS = 'GET_MEMBERS';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const MEMBER_ERROR = 'MEMBER_ERROR';
export const FILTER_MEMBERS = 'FILTER_MEMBERS';
export const CLEAR_MEMBERS = 'CLEAR_MEMBERS';

//Contributions Mkoba
export const ADD_CONTRIBUTION = 'ADD_CONTRIBUTION';
export const GET_CONTRIBUTION = 'GET_CONTRIBUTION';
export const GET_CONTRIBUTIONS = 'GET_CONTRIBUTIONS';
export const UPDATE_CONTRIBUTION = 'UPDATE_CONTRIBUTION';
export const DELETE_CONTRIBUTION = 'DELETE_CONTRIBUTION';
export const CONTRIBUTION_ERROR = 'CONTRIBUTION_ERROR';
export const FILTER_CONTRIBUTIONS = 'FILTER_CONTRIBUTIONS';
export const CLEAR_CONTRIBUTIONS = 'CLEAR_CONTRIBUTIONS';
