import authContext from '../context/auth/authContext';
import contributionContext from '../context/contributions/contributionContext';
import memberContext from '../context/members/memberContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

function MemberContribution() {
  const navigate = useNavigate();
  const { id, name, no } = useParams();
  const { logout, token, user } = useContext(authContext);
  const {
    error,
    current,
    contributions,
    addContribution,
    deleteContribution,
    setCurrent,
    clearCurrent,
    updateContribution,
    getContributions,
    clearContributions,
    clearErrors,
    loading,
  } = useContext(contributionContext);
  const { members, clearMembers } = useContext(memberContext);

  const [input, setInput] = useState({
    id,
    name,
  });

  const [num, setNum] = useState(parseInt(no));

  const [formData, setFormData] = useState({
    week: '',
    weekDate: moment().format('DD/MM/YYYY'),
    hisa: '',
    jamii: '',
    faini: '',
    type: '',
    memberId: input.id,
  });

  const { week, weekDate, hisa, jamii, faini, type } = formData;

  const handleSearch = () => {
    if (num) {
      const foundItem =
        members && members.find((item) => item.memberno === num);

      if (foundItem) {
        setInput({
          id: foundItem._id,
          name: foundItem.name,
        });
        setFormData((prevState) => ({
          ...prevState,
          memberId: foundItem._id,
        }));
      } else {
        if (!members) toast.error('Member No. Not Found');
      }
    }
  };

  useEffect(() => {
    if (current !== null) {
      setFormData(current);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        hisa: '',
        jamii: '',
        faini: '',
      }));
    }
  }, [current]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (current === null) {
      addContribution(formData);
      setFormData((prevState) => ({
        ...prevState,
        hisa: '',
        jamii: '',
        faini: '',
      }));
    } else {
      updateContribution(formData);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrent();
  };

  const ondelete = (id) => {
    if (window.confirm('Are you sure?')) {
      deleteContribution(id);
    }
  };

  const Logout = () => {
    clearContributions();
    clearMembers();
    logout();
    navigate('/login');
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    document.title = 'Member Contributions';
    if (error) {
      toast.error(error);
      clearErrors();
    }
    getContributions();
  }, [token, error]);

  // auto Search members
  useEffect(() => {
    handleSearch();
  }, [num]);

  let BH, BJ, BF, CH, CJ, CF;

  // Specific contribution total based on selected date
  BH =
    contributions &&
    contributions
      .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === weekDate)
      .filter((b) => b.type === 'Bank')
      .reduce((total, x) => total + x.hisa, 0);
  BJ =
    contributions &&
    contributions
      .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === weekDate)
      .filter((b) => b.type === 'Bank')
      .reduce((total, x) => total + x.jamii, 0);
  BF =
    contributions &&
    contributions
      .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === weekDate)
      .filter((b) => b.type === 'Bank')
      .reduce((total, x) => total + x.faini, 0);
  CH =
    contributions &&
    contributions
      .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === weekDate)
      .filter((cash) => cash.type === 'Cash')
      .reduce((total, x) => total + x.hisa, 0);
  CJ =
    contributions &&
    contributions
      .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === weekDate)
      .filter((cash) => cash.type === 'Cash')
      .reduce((total, x) => total + x.jamii, 0);
  CF =
    contributions &&
    contributions
      .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === weekDate)
      .filter((cash) => cash.type === 'Cash')
      .reduce((total, x) => total + x.faini, 0);
  return (
    <>
      <div className='member-header'>
        <Link to={'/collection'} className='btn'>
          Back
        </Link>
        <section className='logout'>
          <span onClick={Logout}>Log Out</span>
        </section>
      </div>
      {user && user.role === 'member' && (
        <section className='heading'>
          <h3>Contributions of</h3>
          <p>{input.name}</p>
        </section>
      )}

      {user && user.role === 'admin' && (
        <div className='admin'>
          <section className='heading'>
            <h3>Contributions of</h3>
            <p className='search'>
              <span> {input.name}</span>
              <input
                type='number'
                name='num'
                id='num'
                min={1}
                readOnly
                max={members && members.length}
                value={num}
                onChange={(e) => {
                  setNum(parseFloat(e.target.value));
                  handleSearch();
                }}
              />
              <button className='searchBtn' onClick={handleSearch}>
                Search
              </button>
            </p>
            <div className='arrows'>
              {/* <i class='fa-solid fa-backward-step'></i>{' '} */}
              <i
                className='fa-solid fa-caret-left numminus'
                onClick={() => {
                  if (num > 1) {
                    if (setNum(num - 1)) handleSearch();
                  }
                }}
              ></i>
              {'  '}
              <i
                className='fa-solid fa-caret-right numplus'
                onClick={() => {
                  if (num < (members && members.length)) {
                    setNum(num + 1);
                  }
                }}
              ></i>{' '}
              {/* <i className='fa-solid fa-forward-step'></i> */}
            </div>
          </section>
          <section className='addForm'>
            <form onSubmit={onSubmit}>
              <div className='weekContainer'>
                <div className='form-group'>
                  <label htmlFor='week'>Week</label>
                  <input
                    type='number'
                    name='week'
                    id='week'
                    min={1}
                    value={week}
                    required
                    onChange={onChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='weekDate'>Date</label>
                  <input
                    type='date'
                    name='weekDate'
                    id='weekDate'
                    required
                    value={weekDate}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className='contributionContainer'>
                <div className='form-group'>
                  <label htmlFor='hisa'>Hisa</label>
                  <input
                    type='number'
                    name='hisa'
                    id='hisa'
                    min={0}
                    required
                    value={hisa}
                    onChange={onChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='jamii'>Jamii</label>
                  <input
                    type='number'
                    name='jamii'
                    id='jamii'
                    min={0}
                    required
                    value={jamii}
                    onChange={onChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='fain'>Fain</label>
                  <input
                    type='number'
                    name='faini'
                    min={0}
                    id='faini'
                    required
                    value={faini}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className='form-group'>
                <select
                  required
                  name='type'
                  id='type'
                  value={type}
                  onChange={onChange}
                >
                  <option value=''>Select Payment Mode</option>
                  <option value='Cash'>Cash</option>
                  <option value='Bank'>Bank</option>
                </select>
              </div>
              <input
                type='submit'
                className='btn btn-block'
                value={current ? 'Update Contribution' : 'Add Contribution'}
              />
              {current && (
                <div>
                  <button
                    className='btn btn-light btn-block'
                    onClick={clearAll}
                  >
                    Clear
                  </button>
                </div>
              )}
            </form>
          </section>
          <section>{loading && <CircularProgress />}</section>
          <div className='weekly-summary'>
            <span className='hisa'>{`BH: ${BH.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}`}</span>
            <span className='jamii'>{`BJ: ${BJ.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}`}</span>
            <span className='faini'>{`BF: ${BF.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}`}</span>
            <span className='hisa'>{`CH: ${CH.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}`}</span>
            <span className='jamii'>{`CJ: ${CJ.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}`}</span>
            <span className='faini'>{`CF: ${CF.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            )}`}</span>
          </div>
        </div>
      )}

      <section className='heading'>
        <h3>
          History
          <span className='contribution_count'>
            {' '}
            (
            {contributions &&
              contributions.filter((member) => member.memberId === input.id)
                .length}
            ) (
            {`H: ${
              contributions &&
              contributions
                .filter((c) => c.memberId === input.id)
                .reduce((total, x) => total + x.hisa, 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } J: ${
              contributions &&
              contributions
                .filter((c) => c.memberId === input.id)
                .reduce((total, x) => total + x.jamii, 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } F: ${
              contributions &&
              contributions
                .filter((c) => c.memberId === input.id)
                .reduce((total, x) => total + x.faini, 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}
            )
          </span>
        </h3>
      </section>
      <section className='contributions'>
        <table className='table table-striped table-c'>
          <thead>
            <tr>
              <th>Week</th>
              <th>Date</th>
              <th>H</th>
              <th>J</th>
              <th>F</th>
              <th>Mode</th>
              {user && user.role === 'admin' && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {contributions &&
              contributions
                .filter((member) => member.memberId === input.id)
                .sort((a, b) => (a.week > b.week ? -1 : 1))
                .map((contribution) => (
                  <tr key={contribution._id}>
                    <td>{contribution.week}</td>
                    <td>
                      {moment(contribution.weekDate).format('DD/MM/YYYY')}
                    </td>
                    <td>{contribution.hisa}</td>
                    <td>{contribution.jamii}</td>
                    <td>{contribution.faini}</td>
                    <td>{contribution.type}</td>
                    {user && user.role === 'admin' && (
                      <td className='singleContribution'>
                        <span
                          onClick={() => setCurrent(contribution)}
                          className='edit'
                        >
                          <i className='fa-solid fa-pen-to-square'></i>
                        </span>
                        <span
                          onClick={() => ondelete(contribution._id)}
                          className='delete'
                        >
                          <i className='fa-solid fa-trash-can'></i>
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
          </tbody>
        </table>
      </section>
    </>
  );
}

export default MemberContribution;
