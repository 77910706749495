import React, { useReducer } from 'react';
import axios from 'axios';
import ContributionContext from './contributionContext';
import contributionReducer from './contributionReducer';

import {
  ADD_CONTRIBUTION,
  DELETE_CONTRIBUTION,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_CONTRIBUTION,
  FILTER_CONTRIBUTIONS,
  CLEAR_FILTER,
  CONTRIBUTION_ERROR,
  GET_CONTRIBUTION,
  CLEAR_CONTRIBUTIONS,
  CLEAR_ERRORS,
  GET_CONTRIBUTIONS,
  SET_LOADING,
} from '../types';

// Initial State
const ContributionState = (props) => {
  const InitialState = {
    contributions: [],
    current: null,
    filtered: null,
    error: null,
    loading: null,
  };

  const [state, dispatch] = useReducer(contributionReducer, InitialState);

  // Get Contributions
  const getContributions = async () => {
    try {
      const res = await axios.get('/api/v1/contributions');
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: CONTRIBUTION_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  //Add Contributions
  const addContribution = async (member) => {
    setLoading();
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/v1/contributions', member, config);
      dispatch({
        type: ADD_CONTRIBUTION,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: CONTRIBUTION_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  // Update Contribution
  const updateContribution = async (contribution) => {
    setLoading();
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.put(
        `/api/v1/contributions/${contribution._id}`,
        contribution,
        config
      );
      dispatch({ type: UPDATE_CONTRIBUTION, payload: res.data });
    } catch (error) {
      dispatch({
        type: CONTRIBUTION_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  //Delete Contribution
  const deleteContribution = async (id) => {
    try {
      await axios.delete(`/api/v1/contributions/${id}`);
      dispatch({ type: DELETE_CONTRIBUTION, payload: id });
    } catch (error) {
      dispatch({
        type: CONTRIBUTION_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  // Set Clear Contributions
  const clearContributions = () => {
    dispatch({ type: CLEAR_CONTRIBUTIONS });
  };

  //Set Current Contribution
  const setCurrent = (contribution) => {
    dispatch({ type: SET_CURRENT, payload: contribution });
  };

  //Clear Current Contribution
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  //Filter Rooms
  // const filterRooms = (text) => {
  //   dispatch({ type: FILTER_MEMBERS, payload: text });
  // };
  // Clear Filter
  // const clearFiltered = () => {
  //   dispatch({ type: CLEAR_FILTER });
  // };
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <ContributionContext.Provider
      value={{
        contributions: state.contributions,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        loading: state.loading,
        addContribution,
        deleteContribution,
        setCurrent,
        clearCurrent,
        updateContribution,
        // filterRooms,
        // clearFiltered,
        getContributions,
        clearContributions,
        clearErrors,
      }}
    >
      {props.children}
    </ContributionContext.Provider>
  );
};

export default ContributionState;
