import authContext from '../context/auth/authContext';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import contributionContext from '../context/contributions/contributionContext';
import memberContext from '../context/members/memberContext';
import moment from 'moment';

function Collection() {
  const navigate = useNavigate();
  const { logout, token, loadUser, user } = useContext(authContext);

  const { members, getMembers, clearMembers } = useContext(memberContext);
  const { contributions, getContributions, clearContributions } =
    useContext(contributionContext);
  const [all, setAll] = useState(true);
  const [searchDate, setSearchDate] = useState('');

  let H, J, F;
  let BH, BJ, BF, CH, CJ, CF;

  if (all) {
    H =
      contributions &&
      contributions.reduce((total, con) => total + con.hisa, 0);
    J =
      contributions &&
      contributions.reduce((total, con) => total + con.jamii, 0);
    F =
      contributions &&
      contributions.reduce((total, con) => total + con.faini, 0);

    // search all for Bank Collection
    BH =
      contributions &&
      contributions
        .filter((b) => b.type === 'Bank')
        .reduce((total, con) => total + con.hisa, 0);
    BJ =
      contributions &&
      contributions
        .filter((b) => b.type === 'Bank')
        .reduce((total, con) => total + con.jamii, 0);
    BF =
      contributions &&
      contributions
        .filter((b) => b.type === 'Bank')
        .reduce((total, con) => total + con.faini, 0);

    // search all for Cash Collection
    CH =
      contributions &&
      contributions
        .filter((c) => c.type === 'Cash')
        .reduce((total, con) => total + con.hisa, 0);
    CJ =
      contributions &&
      contributions
        .filter((c) => c.type === 'Cash')
        .reduce((total, con) => total + con.jamii, 0);
    CF =
      contributions &&
      contributions
        .filter((c) => c.type === 'Cash')
        .reduce((total, con) => total + con.faini, 0);
  } else {
    // Normal Contributions Specific Calculations by Date
    H =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .reduce((total, x) => total + x.hisa, 0);
    J =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .reduce((total, x) => total + x.jamii, 0);
    F =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .reduce((total, x) => total + x.faini, 0);

    // Bank Contribution Specific Calculations by Date
    BH =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .filter((b) => b.type === 'Bank')
        .reduce((total, x) => total + x.hisa, 0);
    BJ =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .filter((b) => b.type === 'Bank')
        .reduce((total, x) => total + x.jamii, 0);
    BF =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .filter((b) => b.type === 'Bank')
        .reduce((total, x) => total + x.faini, 0);

    // Cash Contribution Specific Calculations by Date
    CH =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .filter((cash) => cash.type === 'Cash')
        .reduce((total, x) => total + x.hisa, 0);
    CJ =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .filter((cash) => cash.type === 'Cash')
        .reduce((total, x) => total + x.jamii, 0);
    CF =
      contributions &&
      contributions
        .filter((c) => moment(c.weekDate).format('YYYY-MM-DD') === searchDate)
        .filter((cash) => cash.type === 'Cash')
        .reduce((total, x) => total + x.faini, 0);
  }

  const Logout = () => {
    clearContributions();
    clearMembers();
    logout();
    navigate('/login');
  };

  const view = (id, name, no) => {
    navigate(`/contributions/${id}/${name}/${no}`);
  };

  const dateChange = (date) => {
    setSearchDate(date);
    setAll(false);
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    document.title = 'Collection';
    getMembers();
    getContributions();
    loadUser();
  }, [token]);

  return (
    <>
      <div className='member-header'>
        <div>
          <p>{user && `${user.name}`}</p>
        </div>
        <section className='logout'>
          <span onClick={Logout}>Log Out</span>
        </section>
      </div>
      <section className='collection-heading heading '>
        <h5>Amani Group({user && `${user.groupId}`}) </h5>
        <div className='heading-total'>
          <div className='total'>
            <p className='hisa'>{`H: ${
              H && H.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
            <p className='jamii'>{`J: ${
              J && J.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
            <p className='faini'>{`F: ${
              F && F.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
          </div>
          <div className='total-search'>
            <div className='date-label'>
              <label htmlFor='day'>Search by date</label>
              <input
                type='date'
                name='day'
                id='day'
                value={searchDate}
                onChange={(e) => dateChange(e.target.value)}
              />
            </div>
            <button
              onClick={() => {
                setAll(true);
                setSearchDate('');
              }}
              className={all ? 'all-true' : 'all-false'}
            >
              All
            </button>
          </div>
          <div className='total'>
            <p className='hisa'>{`BH: ${
              BH && BH.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
            <p className='jamii'>{`BJ: ${
              BJ && BJ.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
            <p className='faini'>{`BF: ${
              BF && BF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
            <p className='hisa'>{`CH: ${
              CH && CH.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
            <p className='jamii'>{`CJ: ${
              CJ && CJ.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
            <p className='faini'>{`CF: ${
              CF && CF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }`}</p>
          </div>
        </div>
      </section>

      <section className='collection-content'>
        <table className='table table-striped '>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>H</th>
              <th>J</th>
              <th>F</th>
              <th>
                <i className='fa-brands fa-buffer'></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {members &&
              members.map((member) => (
                <tr key={member._id}>
                  {/* Display only those with H = 0  */}

                  {!all &&
                    contributions
                      ?.filter((c) => c.memberId === member._id)
                      .filter(
                        (c) =>
                          moment(c.weekDate).format('YYYY-MM-DD') === searchDate
                      )
                      .reduce((total, x) => total + x.hisa, 0) !== 0 && (
                      <>
                        <td>{member.memberno}</td>
                        <td>{member.name}</td>
                        <td>
                          {/* filter for specifics */}
                          {contributions
                            ?.filter((c) => c.memberId === member._id)
                            .filter(
                              (c) =>
                                moment(c.weekDate).format('YYYY-MM-DD') ===
                                searchDate
                            )
                            .reduce((total, x) => total + x.hisa, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                          {/* filter for Bank(B) or Cash(C) */}
                          {contributions
                            ?.filter((c) => c.memberId === member._id)
                            .filter(
                              (c) =>
                                moment(c.weekDate).format('YYYY-MM-DD') ===
                                searchDate
                            )
                            .map((c) => c.type)
                            .includes('Bank')
                            ? 'B'
                            : contributions &&
                              contributions
                                .filter((c) => c.memberId === member._id)
                                .filter(
                                  (c) =>
                                    moment(c.weekDate).format('YYYY-MM-DD') ===
                                    searchDate
                                )
                                .map((c) => c.type)
                                .includes('Cash')
                            ? 'C'
                            : ''}
                        </td>
                        <td>
                          {contributions
                            ?.filter((c) => c.memberId === member._id)
                            .filter(
                              (c) =>
                                moment(c.weekDate).format('YYYY-MM-DD') ===
                                searchDate
                            )
                            .reduce((total, x) => total + x.jamii, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                          {/* filter for Bank(B) or Cash(C) */}
                          {contributions
                            ?.filter((c) => c.memberId === member._id)
                            .filter(
                              (c) =>
                                moment(c.weekDate).format('YYYY-MM-DD') ===
                                searchDate
                            )
                            .map((c) => c.type)
                            .includes('Bank')
                            ? 'B'
                            : contributions &&
                              contributions
                                .filter((c) => c.memberId === member._id)
                                .filter(
                                  (c) =>
                                    moment(c.weekDate).format('YYYY-MM-DD') ===
                                    searchDate
                                )
                                .map((c) => c.type)
                                .includes('Cash')
                            ? 'C'
                            : ''}
                        </td>
                        <td>
                          {contributions
                            ?.filter((c) => c.memberId === member._id)
                            .filter(
                              (c) =>
                                moment(c.weekDate).format('YYYY-MM-DD') ===
                                searchDate
                            )
                            .reduce((total, x) => total + x.faini, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                          {/* filter for Bank(B) or Cash(C) */}
                          {contributions
                            ?.filter((c) => c.memberId === member._id)
                            .filter(
                              (c) =>
                                moment(c.weekDate).format('YYYY-MM-DD') ===
                                searchDate
                            )
                            .map((c) => c.type)
                            .includes('Bank')
                            ? 'B'
                            : contributions &&
                              contributions
                                .filter((c) => c.memberId === member._id)
                                .filter(
                                  (c) =>
                                    moment(c.weekDate).format('YYYY-MM-DD') ===
                                    searchDate
                                )
                                .map((c) => c.type)
                                .includes('Cash')
                            ? 'C'
                            : ''}
                        </td>
                        <td
                          onClick={() =>
                            view(member._id, member.name, member.memberno)
                          }
                          className='view'
                        >
                          <i className='fa-solid fa-eye'></i>
                        </td>
                      </>
                    )}
                  {all && (
                    <>
                      <td>{member.memberno}</td>
                      <td>{member.name}</td>
                      <td>
                        {contributions
                          ?.filter((c) => c.memberId === member._id)
                          .reduce((total, x) => total + x.hisa, 0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td>
                        {contributions
                          ?.filter((c) => c.memberId === member._id)
                          .reduce((total, x) => total + x.jamii, 0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td>
                        {contributions
                          ?.filter((c) => c.memberId === member._id)
                          .reduce((total, x) => total + x.faini, 0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td
                        onClick={() =>
                          view(member._id, member.name, member.memberno)
                        }
                        className='view'
                      >
                        <i className='fa-solid fa-eye'></i>
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </section>
    </>
  );
}

export default Collection;
