import authContext from '../context/auth/authContext';
import memberContext from '../context/members/memberContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useContext, useEffect, useState } from 'react';

function Members() {
  const navigate = useNavigate();
  const { logout, token } = useContext(authContext);
  const {
    addMember,
    members,
    getMembers,
    error,
    clearErrors,
    current,
    setCurrent,
    clearCurrent,
    updateMember,
    clearMembers,
    deleteMember,
  } = useContext(memberContext);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    memberno: '',
  });

  const { name, phone, memberno } = formData;

  useEffect(() => {
    if (current !== null) {
      setFormData(current);
    } else {
      setFormData({
        name: '',
        phone: '',
        memberno: '',
      });
    }
  }, [current]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (current === null) {
      addMember(formData);
      setFormData({
        name: '',
        phone: '',
        memberno: '',
      });
    } else {
      updateMember(formData);
    }
    clearAll();
  };

  const ondelete = (id) => {
    if (window.confirm('Are you sure?')) {
      deleteMember(id);
    }
  };
  const Logout = () => {
    clearMembers();
    logout();
  };

  const clearAll = () => {
    clearCurrent();
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    document.title = 'Members';
    if (error) {
      toast.error(error);
      clearErrors();
    }
  }, [token, error]);
  return (
    <>
      <div className='member-header'>
        {members && <span>Total members: {members.length}</span>}
        <section className='logout'>
          <span onClick={Logout}>Log Out</span>
        </section>
      </div>

      <section className='heading'>
        <p>Add Members</p>
      </section>
      <section className='members'>
        <div className='form'>
          <form onSubmit={onSubmit}>
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <input
                type='text'
                name='name'
                id='name'
                value={name}
                onChange={onChange}
              />
            </div>
            <div className='phone-number-grid'>
              <div className='form-group'>
                <label htmlFor='phone'>Phone</label>
                <input
                  type='text'
                  name='phone'
                  id='phone'
                  value={phone}
                  onChange={onChange}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='memberno'>No.</label>
                <input
                  type='number'
                  name='memberno'
                  id='memberno'
                  value={memberno}
                  onChange={onChange}
                />
              </div>
            </div>
            <input
              type='submit'
              className='btn btn-block'
              value={current ? 'Update Member' : 'Add Member'}
            />
            {current && (
              <div>
                <button className='btn btn-light btn-block' onClick={clearAll}>
                  Clear
                </button>
              </div>
            )}
          </form>
        </div>
        <hr className='divider' />
        <div className='member-list'>
          {members &&
            members.map((member) => (
              <div className='card' key={member._id}>
                <p>
                  <span>{member.name}</span>
                  <span className='number'>{member.memberno}</span>
                </p>
                <span>
                  <i className='fa-solid fa-phone'></i>
                  {`+255${member.phone}`}
                </span>
                <div className='edit'>
                  <span onClick={() => setCurrent(member)}>
                    <i className='fa-solid fa-pen-to-square'></i>Edit
                  </span>
                  <span onClick={() => ondelete(member._id)}>
                    <i className='fa-solid fa-trash-can'></i>Delete
                  </span>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
}

export default Members;
