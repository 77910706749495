import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import authContext from '../context/auth/authContext';
import { Detector } from 'react-detect-offline';
import { Badge } from '@material-ui/core';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, user } = useContext(authContext);

  const pathMatchRoute = (route) => {
    if (route === location.pathname) {
      return true;
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else {
      navigate('/collection');
    }
  }, [token]);
  return (
    <>
      <div className='offline'>
        <Detector
          render={({ online }) => (
            <Badge
              badgeContent={online ? 'Online' : 'Offline'}
              color={online ? 'primary' : 'error'}
              sx={{ mt: 2 }}
            ></Badge>
          )}
        />
      </div>
      <Outlet />
      <footer className='navbar'>
        <nav className='navbarNav'>
          <ul className='navbarListItems'>
            <li
              className='navbarListItem'
              onClick={() => navigate('/collection')}
            >
              <i className='fa-solid fa-align-left'></i>{' '}
              <p
                className={
                  pathMatchRoute('/collection')
                    ? 'navbarListItemNameActive'
                    : 'navbarListItemName'
                }
              >
                Collections
              </p>
            </li>
            {/* <li
              className='navbarListItem'
              onClick={() => navigate('/contributions')}
            >
              <i className='fa-solid fa-coins'></i>
              <p
                className={
                  pathMatchRoute('/contributions')
                    ? 'navbarListItemNameActive'
                    : 'navbarListItemName'
                }
              >
                Contribution
              </p>
            </li> */}
            {user && user.role === 'admin' && (
              <li
                className='navbarListItem'
                onClick={() => navigate('/profile')}
              >
                <i className='fa-solid fa-user'></i>
                <p
                  className={
                    pathMatchRoute('/profile')
                      ? 'navbarListItemNameActive'
                      : 'navbarListItemName'
                  }
                >
                  profile
                </p>
              </li>
            )}
          </ul>
        </nav>
      </footer>
    </>
  );
}

export default Navbar;
