import authContext from '../context/auth/authContext';
import memberContext from '../context/members/memberContext';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';

function Contributions() {
  const navigate = useNavigate();
  const { logout, token } = useContext(authContext);
  const { members, getMembers } = useContext(memberContext);

  const Logout = () => {
    logout();
  };

  const view = (id, name, no) => {
    navigate(`/contributions/${id}/${name}/${no}`);
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    document.title = 'Contributions';
    getMembers();
  }, [token]);
  return (
    <>
      <div className='member-header'>
        <p>{`Amani's Collection tour`}</p>
        <section className='logout'>
          <span onClick={Logout}>Log Out</span>
        </section>
      </div>
      <section className='heading'>
        <h1>Members </h1>
      </section>
      <section className='contributions'>
        <table className='table table-striped table-c'>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {members &&
              members.map((member) => (
                <tr key={member._id}>
                  <td>{member.memberno}</td>
                  <td>
                    {member.name}
                    <br />
                    <small>0718156655</small>
                  </td>
                  <td
                    onClick={() =>
                      view(member._id, member.name, member.memberno)
                    }
                    className='view'
                  >
                    View
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </section>
    </>
  );
}

export default Contributions;
