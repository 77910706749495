import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authContext from '../context/auth/authContext';

import contributionContext from '../context/contributions/contributionContext';
import memberContext from '../context/members/memberContext';
import moment from 'moment';

function Reports() {
  const navigate = useNavigate();
  const { token } = useContext(authContext);

  const { members, getMembers } = useContext(memberContext);
  const { contributions, getContributions } = useContext(contributionContext);

  const [search, setSearch] = useState('01');

  const selected =
    contributions &&
    contributions
      .filter((f) => moment(f.weekDate).format('MM') === search)
      .map((m) => m.weekDate);
  const monthDates = [...new Set(selected)].sort();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    document.title = 'Reports';
    getMembers();
    getContributions();
  }, [token]);

  return (
    <div className='reports'>
      <section className='heading'>
        <h3>Reports</h3>
      </section>
      <div className='search'>
        <select
          name='datesearch'
          id='datesearch'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        >
          <option value='01'>January</option>
          <option value='02'>February</option>
          <option value='03'>March</option>
          <option value='04'>April</option>
          <option value='05'>May</option>
          <option value='06'>June</option>
          <option value='07'>July</option>
          <option value='08'>August</option>
          <option value='09'>September</option>
          <option value='10'>October</option>
          <option value='11'>November</option>
          <option value='12'>December</option>
        </select>
      </div>
      <hr />
      {monthDates.length !== 0 && (
        <section>
          <table className='table table-bordered table-striped'>
            <thead>
              <tr>
                <td rowSpan={2}>#</td>
                <td className='tborder-right' rowSpan={2}>
                  Name
                </td>
                {monthDates &&
                  monthDates.map((row) => (
                    <>
                      <td key={row} className='tborder-right' colSpan={4}>
                        {moment(row).format('DD/MM/YYYY')}
                      </td>
                    </>
                  ))}
              </tr>
              <tr>
                {monthDates &&
                  monthDates.map((row) => (
                    <>
                      <td>H</td>
                      <td>J</td>
                      <td>F</td>
                      <td className='tborder-right'>T</td>
                    </>
                  ))}
              </tr>
            </thead>
            <tbody>
              {members &&
                members.map((member) => (
                  <tr key={member._id}>
                    <td>{member.memberno}</td>
                    <td className='tborder-right'>{member.name}</td>
                    {monthDates &&
                      monthDates.map((row) => (
                        <>
                          <td className='tborder-left'>
                            {contributions &&
                              contributions
                                .filter((c) => c.memberId === member._id)
                                .filter((c) => c.weekDate === row)
                                .reduce((total, x) => total + x.hisa, 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </td>
                          <td>
                            {contributions &&
                              contributions
                                .filter((c) => c.memberId === member._id)
                                .filter((c) => c.weekDate === row)
                                .reduce((total, x) => total + x.jamii, 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </td>
                          <td>
                            {contributions &&
                              contributions
                                .filter((c) => c.memberId === member._id)
                                .filter((c) => c.weekDate === row)
                                .reduce((total, x) => total + x.faini, 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </td>
                          <td className='tborder-right'>
                            {contributions &&
                            contributions
                              .filter((c) => c.memberId === member._id)
                              .filter((c) => c.weekDate === row)
                              .map((c) => c.type)
                              .includes('Bank')
                              ? 'B'
                              : contributions &&
                                contributions
                                  .filter((c) => c.memberId === member._id)
                                  .filter((c) => c.weekDate === row)
                                  .map((c) => c.type)
                                  .includes('Cash')
                              ? 'C'
                              : ''}
                          </td>
                        </>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
      )}

      {monthDates.length === 0 && (
        <div className='heading'>
          <p>There is no Data for this Month</p>
        </div>
      )}
    </div>
  );
}

export default Reports;
