import {
  ADD_CONTRIBUTION,
  DELETE_CONTRIBUTION,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_CONTRIBUTION,
  FILTER_CONTRIBUTIONS,
  CLEAR_FILTER,
  CONTRIBUTION_ERROR,
  GET_CONTRIBUTION,
  CLEAR_CONTRIBUTIONS,
  CLEAR_ERRORS,
  GET_CONTRIBUTIONS,
  SET_LOADING,
} from '../types';

const ContributionReducer = (state, action) => {
  switch (action.type) {
    case GET_CONTRIBUTIONS:
      return {
        ...state,
        contributions: action.payload,
        loading: false,
      };
    case ADD_CONTRIBUTION:
      return {
        ...state,
        contributions: [...state.contributions, action.payload],
        loading: false,
      };
    case UPDATE_CONTRIBUTION:
      return {
        ...state,
        contributions: state.contributions.map((contribution) =>
          contribution._id === action.payload._id
            ? action.payload
            : contribution
        ),
        loading: false,
      };

    case DELETE_CONTRIBUTION:
      return {
        ...state,
        contributions: state.contributions.filter(
          (contribution) => contribution._id !== action.payload
        ),
        loading: false,
      };
    case CLEAR_CONTRIBUTIONS:
      return {
        ...state,
        contributions: null,
        filtered: null,
        error: null,
        current: null,
        loading: null,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case FILTER_CONTRIBUTIONS:
      return {
        ...state,
        filtered: state.contributions.filter((contribution) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return contribution.name.match(regex);
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case CONTRIBUTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: null,
      };
    default:
      return state;
  }
};

export default ContributionReducer;
