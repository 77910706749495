import authContext from '../context/auth/authContext';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';

function Profile() {
  const navigate = useNavigate();
  const { logout, token } = useContext(authContext);

  const Logout = () => {
    logout();
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token]);
  return (
    <>
      <div className='member-header'>
        <p>{`Amani's Collection`}</p>
        <section className='logout'>
          <span onClick={Logout}>Log Out</span>
        </section>
      </div>
      <section className='heading'>
        <h1>Dashboard </h1>
      </section>
      <section className='profile'>
        <Link to={'/members'} className='card'>
          Add Members
        </Link>
        <Link to={'/contributions'} className='card'>
          Add Contributions
        </Link>
        <Link to={'/reports'} className='card'>
          Reports
        </Link>
      </section>
    </>
  );
}

export default Profile;
