import React, { useReducer } from 'react';
import axios from 'axios';
import MemberContext from './memberContext';
import memberReducer from './memberReducer';

import {
  ADD_MEMBER,
  DELETE_MEMBER,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_MEMBER,
  FILTER_MEMBERS,
  CLEAR_FILTER,
  MEMBER_ERROR,
  GET_MEMBER,
  CLEAR_MEMBERS,
  CLEAR_ERRORS,
  GET_MEMBERS,
} from '../types';

// Initial State
const MemberState = (props) => {
  const InitialState = {
    members: [],
    current: null,
    filtered: null,
    error: null,
    loading: null,
  };

  const [state, dispatch] = useReducer(memberReducer, InitialState);

  // Get Members
  const getMembers = async () => {
    try {
      const res = await axios.get('/api/v1/members');
      dispatch({
        type: GET_MEMBERS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: MEMBER_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  //Add Members
  const addMember = async (member) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/v1/members', member, config);
      dispatch({
        type: ADD_MEMBER,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: MEMBER_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  // Update Member
  const updateMember = async (member) => {
    console.log(member);
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.put(
        `/api/v1/members/${member._id}`,
        member,
        config
      );
      dispatch({ type: UPDATE_MEMBER, payload: res.data });
    } catch (error) {
      dispatch({
        type: MEMBER_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  //Delete Member
  const deleteMember = async (id) => {
    try {
      await axios.delete(`/api/v1/members/${id}`);
      dispatch({ type: DELETE_MEMBER, payload: id });
    } catch (error) {
      dispatch({
        type: MEMBER_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  // Set Clear Members
  const clearMembers = () => {
    dispatch({ type: CLEAR_MEMBERS });
  };

  //Set Current Member
  const setCurrent = (member) => {
    dispatch({ type: SET_CURRENT, payload: member });
  };

  //Clear Current Member
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  //Filter Rooms
  // const filterRooms = (text) => {
  //   dispatch({ type: FILTER_MEMBERS, payload: text });
  // };
  // Clear Filter
  // const clearFiltered = () => {
  //   dispatch({ type: CLEAR_FILTER });
  // };
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });
  return (
    <MemberContext.Provider
      value={{
        members: state.members,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        addMember,
        deleteMember,
        setCurrent,
        clearCurrent,
        updateMember,
        // filterRooms,
        // clearFiltered,
        getMembers,
        clearMembers,
        clearErrors,
      }}
    >
      {props.children}
    </MemberContext.Provider>
  );
};

export default MemberState;
